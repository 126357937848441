import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { AuthenticationImage, useAuthStore } from "./pages/login/Login";
import AuthenticatedRoutes from "./AuthenticatedApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const authenticated = useAuthStore((state) => !!state.token);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <div className="App" style={{ height: "100%" }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <MantineProvider
            theme={{
              colorScheme: "dark",
              primaryColor: "red",
              loader: "bars",
              colors: {
                "ou-colors": [
                  "#fcf5f6",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                  "#a32135",
                ],
              },
            }}
          >
            {!authenticated && <AuthenticationImage />}
            {authenticated && <AuthenticatedRoutes />}
          </MantineProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
