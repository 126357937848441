import {
  AppShell,
  Group,
  Header,
  ThemeIcon,
  UnstyledButton,
  Text,
  Navbar,
  Image,
  createStyles,
  MediaQuery,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import { IconLogout } from "@tabler/icons";
import { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { User, Users } from "tabler-icons-react";
import Individual, { useAthletesData } from "./individual/Individual";
import IndividualSelect from "./individual/IndividualSelect";
import { useAuthStore } from "./pages/login/Login";
import Team from "./team/Team";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

function MainLink({ icon, color, label, href }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <UnstyledButton
      className={
        location.pathname.slice(1).toLowerCase().startsWith(label.toLowerCase())
          ? "active"
          : ""
      }
      sx={(theme) => ({
        ...theme.fn.focusStyles(),
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[1]
            : theme.colors.gray[7],
        "&.active": {
          backgroundColor: `${
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0]
          }`,
        },
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          color: theme.colorScheme === "dark" ? theme.white : theme.black,

          [`& .${icon}`]: {
            color: theme.colorScheme === "dark" ? theme.white : theme.black,
          },
        },
      })}
      onClick={() => {
        navigate(href);
      }}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text
          size="sm"
          weight={
            location.pathname
              .slice(1)
              .toLowerCase()
              .startsWith(label.toLowerCase())
              ? "bold"
              : "normal"
          }
        >
          {label}
        </Text>
      </Group>
    </UnstyledButton>
  );
}

const AuthenticatedRoutes = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6),
    new Date(),
  ]);
  const {
    displayData: athletes,
    isFetching,
    isLoading,
    error,
  } = useAthletesData(dateRange);

  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar
          width={{ base: 200 }}
          p="xs"
          hiddenBreakpoint="sm"
          hidden={!opened}
        >
          <Navbar.Section grow>
            <MainLink
              icon={<User size={16} />}
              color="ou-colors"
              label="Individual"
              href="/individual"
            />
            <MainLink
              icon={<Users size={16} />}
              color="ou-colors"
              label="Team"
              href="/team"
            />
          </Navbar.Section>
          <Navbar.Section className={classes.footer}>
            <a
              href="/"
              className={classes.link}
              onClick={(event) => {
                event.preventDefault();
                logout();
              }}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Logout</span>
            </a>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={100} p="xs">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div
              style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
            >
              <Image
                radius="md"
                src="/ou_basketball.png"
                alt="OU Logo"
                height={80}
                width={"auto"}
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Routes>
        <Route
          path="/"
          element={
            <IndividualSelect
              dateRange={dateRange}
              setDateRange={setDateRange}
              athletes={athletes}
              isFetching={isFetching}
              isLoading={isLoading}
              error={error}
            />
          }
        ></Route>
        <Route
          path="/team"
          element={<Team dateRange={dateRange} setDateRange={setDateRange} />}
        ></Route>
        <Route
          path="/individual/:id"
          element={
            <Individual
              dateRange={dateRange}
              setDateRange={setDateRange}
              athletes={athletes}
            />
          }
        ></Route>
        <Route
          path="/individual"
          element={
            <IndividualSelect
              dateRange={dateRange}
              setDateRange={setDateRange}
              athletes={athletes}
              isFetching={isFetching}
              isLoading={isLoading}
              error={error}
            />
          }
        />
      </Routes>
    </AppShell>
  );
};

export default AuthenticatedRoutes;
