import { Card, createStyles } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataComponent } from "../components/DataComponent";
import { Athlete } from "./Individual";

const playerPositionMap = {
  "Joe Bamisile": "Guard",
  "Bijan Cortes": "Guard",
  "Sam Godwin": "Forward",
  "Jake Groves": "Forward",
  "Tanner Groves": "Center",
  "Jalen Hill": "Forward",
  "Yaya Keita": "Forward",
  "Jake Moser": "Guard",
  "CJ Noland": "Guard",
  "Luke Northweather": "Center",
  "Otega Oweh": "Guard",
  "Benny Schroder": "Guard",
  "Blake Seacat": "Guard",
  "Grant Sherfield": "Guard",
  "Milos Uzan": "Guard",
};

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.md,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

type IIndividualSelect = {
  dateRange: [Date | null, Date | null];
  setDateRange: (dateRange: [Date | null, Date | null]) => void;
  athletes?: Athlete[];
  isFetching: boolean;
  isLoading: boolean;
  error?: unknown;
};

const IndividualSelect: React.FC<IIndividualSelect> = ({
  dateRange,
  setDateRange,
  athletes,
  isFetching,
  isLoading,
  error,
}) => {
  const navigate = useNavigate();

  const { classes } = useStyles();

  const [athleteReadiness, setAthleteReadiness] = useState<any>();

  useEffect(() => {
    if (!athletes) {
      return;
    }

    const localStore = localStorage.getItem("athleteReadiness")!;
    if (!localStore || JSON.parse(localStore).length !== athletes.length) {
      const newReadiness = athletes?.map((athlete) => {
        const min = 65;
        const max = 85;
        return {
          id: athlete.id,
          readiness: Math.round(Math.random() * (max - min)) + min,
        };
      });
      setAthleteReadiness(newReadiness);
      localStorage.setItem("athleteReadiness", JSON.stringify(newReadiness));
    } else {
      setAthleteReadiness(
        JSON.parse(localStorage.getItem("athleteReadiness")!)
      );
    }
  }, [athletes]);

  return (
    <div className={classes.root}>
      <DateRangePicker
        label="Date Select"
        placeholder="Pick dates range"
        value={dateRange}
        onChange={setDateRange}
      />
      {athletes?.length === 0 && (
        <p style={{ color: "white" }}>
          No athletes within the selected time frame.
        </p>
      )}
      <DataComponent isLoading={isLoading && isFetching} error={error}>
        <div className="auto-grid" style={{ minHeight: 205 }}>
          {athletes
            ?.sort((athlete, athlete_two) =>
              athlete.name
                .split(" ")[1]
                .localeCompare(athlete_two.name.split(" ")[1])
            )
            .map((athlete) => {
              const percent = athleteReadiness?.find(
                (readiness: any) => readiness.id === athlete.id
              )?.readiness;
              return (
                <Card
                  withBorder
                  radius="md"
                  key={athlete.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/individual/" + athlete.id);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "2.5rem",
                      padding: ".5rem 1.5rem",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        flexShrink: 0,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          right: 0,
                          top: 0,
                          bottom: 0,
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxWidth: "80px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          transform: "translateY(15px)",
                          gap: ".5rem",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            textAlign: "center",
                            maxWidth: "100px",
                            fontFamily: "Jersey M54",
                            fontSize: "14px",
                            margin: 0,
                          }}
                        >
                          {athlete.name}
                        </p>
                        <p
                          style={{
                            color: "white",
                            textAlign: "center",
                            maxWidth: "100px",
                            fontFamily: "Jersey M54",
                            fontSize: "44px",
                            margin: 0,
                          }}
                        >
                          {athlete.jersey}
                        </p>
                      </div>
                      <svg
                        width="auto"
                        height="155"
                        viewBox="0 0 107 185"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M89.0985 4.43894C90.5533 12.5122 94.6787 30.0583 104.726 44.0731C106.497 46.5428 107.247 49.5993 106.929 52.6212L101.946 99.9618C101.388 105.259 101.109 110.583 101.109 115.909V180.913C101.109 182.665 99.6875 184.087 97.9347 184.087H9.06523C7.31244 184.087 5.89116 182.665 5.89116 180.913V115.91C5.89116 110.583 5.6118 105.26 5.05414 99.9622L0.071205 52.6216C-0.246992 49.5993 0.503738 46.5431 2.27378 44.0734C12.3213 30.0583 16.4463 12.5118 17.9014 4.4393C18.3656 1.86388 20.594 0 23.2101 0H83.7895C86.4059 0 88.6344 1.86388 89.0985 4.43894Z"
                          fill="#FF5A64"
                        />
                        <path
                          d="M18.7118 2.47799C19.9878 20.5983 35.0546 34.9129 53.5 34.9129C71.9454 34.9129 87.0121 20.598 88.2881 2.47799C87.3188 0.983356 85.6681 0 83.7899 0H23.2101C21.3318 0 19.6812 0.983356 18.7118 2.47799Z"
                          fill="#D74B55"
                        />
                        <path
                          d="M24.9349 0C24.9349 15.7761 37.7239 28.5651 53.5 28.5651C69.2761 28.5651 82.0652 15.7761 82.0652 0H24.9349Z"
                          fill="#D5DCED"
                        />
                        <path
                          d="M34.4563 0C34.4563 10.5174 42.9822 19.0437 53.5 19.0437C64.0174 19.0437 72.5436 10.5178 72.5436 0H34.4563Z"
                          fill="#C8414B"
                        />
                      </svg>
                    </div>

                    <div
                      style={{
                        flexBasis: "50%",
                        flexShrink: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          flexGrow: 1,
                        }}
                      >
                        <div style={{ flexBasis: "50%" }}>
                          <p
                            style={{
                              margin: 0,
                              fontWeight: 300,
                              marginBottom: ".25rem",
                              fontSize: 14,
                            }}
                          >
                            Position
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              color: "white",
                              fontSize: 17,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {(playerPositionMap as any)[athlete.name] ??
                              "Forward"}
                          </p>
                        </div>
                        <div style={{ flexBasis: "50%" }}>
                          <p
                            style={{
                              margin: 0,
                              fontWeight: 300,
                              marginBottom: ".25rem",
                              fontSize: 14,
                            }}
                          >
                            Weight
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              color: "white",
                              fontSize: 17,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {Math.round(athlete.weight * 2.205 * 10) / 10} lbs
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "min-content",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            bottom: 2,
                            width: "100%",
                            left: 0,
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: "17px",
                            margin: 0,
                            marginBlockEnd: 0,
                          }}
                        >
                          {percent}%
                          <br />
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: "normal",
                              color: "#C1C2C5",
                            }}
                          >
                            Readiness
                          </span>
                        </p>
                        <svg
                          style={{ width: "auto", height: "70px" }}
                          viewBox="0 0 112 59"
                        >
                          <path
                            id="arc1"
                            fill="none"
                            stroke="#373A40"
                            strokeWidth={6}
                            strokeLinecap="round"
                            shapeRendering="geometricPrecision"
                            d={describeArc(56, 56, 50, 270, 90)}
                          />
                          <path
                            id="arc1"
                            fill="none"
                            stroke={
                              percent >= 70
                                ? "#00B74A"
                                : percent >= 40
                                ? "#FFA900"
                                : "#FF5A64"
                            }
                            strokeWidth={6}
                            strokeLinecap="round"
                            shapeRendering="geometricPrecision"
                            d={describeArc(56, 56, 50, 270, percent * 1.8 - 90)}
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
        </div>
      </DataComponent>
    </div>
  );
};

export default IndividualSelect;
