import { createStyles, Indicator, Select, Tabs } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { DataComponent } from "../components/DataComponent";
import { apiUrl } from "../config";
import { useAuthStore } from "../pages/login/Login";
import api from "../util/api";

export const AXIS_LABEL_MAP: any = {
  ECCENTRIC_PEAK_POWER: {
    yAxis: "Watts",
    seriesName: "Peak Power",
  },
  JUMP_HEIGHT: {
    yAxis: "Inches",
    seriesName: "Jump Height",
  },
  RSI_MODIFIED: {
    yAxis: "Reactive Strenght Index",
    seriesName: "RSI",
  },
  CONCENTRIC_RFD: {
    yAxis: "Concentric Rate of Force Development",
    seriesName: "Concentric RFD",
  },
  MEAN_TAKEOFF_FORCE: {
    yAxis: "Concentric Mean Force",
    seriesName: "Concentric Mean Force",
  },
  MEAN_CONCENTRIC_POWER: {
    yAxis: "Concentric Mean Power",
    seriesName: "Concentric Mean Power",
  },
  MEAN_ECCENTRIC_FORCE: {
    yAxis: "Eccentric Mean Force",
    seriesName: "Eccentric Mean Force",
  },
  MEAN_ECCENTRIC_POWER: {
    yAxis: "Eccentric Mean Power",
    seriesName: "Eccentric Mean Power",
  },
  FLIGHT_CONTRACTION_TIME_RATIO: {
    yAxis: "Flight Time : Contraction Time",
    seriesName: "Flight Time : Contraction Time",
  },
  PEAK_LANDING_FORCE: {
    yAxis: "Peak Landing Force",
    seriesName: "Peak Landing Force",
  },
};

export type Athlete = {
  name: string;
  id: number;
  catapult_id: number;
  forcedecks_id: number;
  jersey: string;
  weight: number;
};

export const useAthletesData = (dateRange: [Date | null, Date | null]) => {
  const token = useAuthStore((state) => state.token);

  const queryProps = useQuery(
    ["athlete-data", JSON.stringify(dateRange)],
    () =>
      api
        .get(`${apiUrl}/athletes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            fromDate: dateRange[0]?.toISOString(),
            toDate: dateRange[1]?.toISOString(),
          },
        })
        .then((data) => data.data as Athlete[]),
    { enabled: !!dateRange[0] && !!dateRange[1] }
  );

  const [displayData, setDisplayData] = useState<Athlete[]>();

  useEffect(() => {
    if (!queryProps.data) {
      return;
    }

    setDisplayData(queryProps.data);
  }, [queryProps.data]);

  return { ...queryProps, displayData };
};

export const useCatapultData = (
  dateRange: [Date | null, Date | null],
  rollingWindowSize = "3"
) => {
  const queryProps = useQuery(
    ["catapult-data", JSON.stringify(dateRange), rollingWindowSize],
    () =>
      api
        .get(`${apiUrl}/catapultData`, {
          params: {
            startDate: dateRange[0]?.toISOString(),
            endDate: dateRange[1]?.toISOString(),
            rollingWindowSize: rollingWindowSize,
          },
        })
        .then((data) => {
          return data.data.sort(
            (a: any, b: any) =>
              new Date(a.date_id).getTime() - new Date(b.date_id).getTime()
          );
        }),
    {
      enabled: !!dateRange[0] && !!dateRange[1],
    }
  );

  const [displayData, setDisplayData] = useState<any[]>();

  useEffect(() => {
    if (!queryProps.data) {
      return;
    }

    setDisplayData(queryProps.data);
  }, [queryProps.data]);

  return { ...queryProps, displayData };
};

export const useForceDecksData = (dateRange: [Date | null, Date | null]) => {
  const queryProps = useQuery(
    ["catapult-data", JSON.stringify(dateRange)],
    () =>
      api
        .get(`${apiUrl}/forcedecksData`, {
          params: {
            fromDate: dateRange[0]?.toISOString(),
            toDate: dateRange[1]?.toISOString(),
          },
        })
        .then((data) => data.data),
    {
      enabled: !!dateRange[0] && !!dateRange[1],
    }
  );

  const [displayData, setDisplayData] = useState<any[]>();

  useEffect(() => {
    if (!queryProps.data) {
      return;
    }

    setDisplayData(queryProps.data);
  }, [queryProps.data]);

  return { ...queryProps, displayData };
};

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.md,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

type IIndividual = {
  dateRange: [Date | null, Date | null];
  setDateRange: (dateRange: [Date | null, Date | null]) => void;
  athletes?: Athlete[];
};

const Individual: React.FC<IIndividual> = ({
  dateRange,
  setDateRange,
  athletes,
}) => {
  let params = useParams();
  const [rollingWindowSize, setRollingWindowSize] = useState("3");
  const [mainActiveTab, setMainActiveTab] = useState<string | null>(
    "player-load"
  );
  const [activeTab, setActiveTab] = useState<string | null>(
    "Total Player Load"
  );
  const [selectedParameter, setSelectedParameter] = useState(
    "ECCENTRIC_PEAK_POWER"
  );
  const [selectedAthleteId, setSelectedAthleteId] = useState(params.id);
  const selectedAthlete = useMemo(() => {
    return athletes?.find(
      (athlete) => athlete.id.toString() === selectedAthleteId
    );
  }, [selectedAthleteId, athletes]);
  let { displayData, isLoading, isFetching, error } = useCatapultData(
    dateRange,
    rollingWindowSize
  );
  isLoading = true;
  const {
    displayData: fdDisplayData,
    isLoading: fdIsLoading,
    isFetching: fdIsFetching,
    error: fdError,
  } = useForceDecksData(dateRange);

  useEffect(() => {
    if (!selectedAthleteId) {
      setSelectedAthleteId(athletes?.[0].id.toString());
    }
  }, [athletes, selectedAthleteId]);

  const athleteData = useMemo(() => {
    return (
      displayData?.map((item: any) => {
        return {
          athleteId: item.athlete_id,
          date: item.date_id,
          totalPlayerLoad: item.total_player_load,
          playerLoadPerMinute: item.player_load_per_minute,
          totalPlayerLoadRolling: item.total_player_load_rolling,
          playerLoadPerMinuteRolling: item.player_load_per_minute_rolling,
        };
      }) ?? []
    );
  }, [displayData]);
  const maxPlayerLoad = useMemo(() => {
    return Math.max(...athleteData.map((data: any) => data.totalPlayerLoad));
  }, [athleteData]);
  const maxPlayerLoadPerMinute = useMemo(() => {
    return Math.max(
      ...athleteData.map((data: any) => data.playerLoadPerMinute)
    );
  }, [athleteData]);
  const selectedAthleteData = useMemo(() => {
    return athleteData.filter((data: any) => {
      return data.athleteId === selectedAthlete?.catapult_id;
    });
  }, [athleteData, selectedAthlete]);
  const selectedAthleteFDData = useMemo(() => {
    return fdDisplayData?.filter((data: any) => {
      return data.athleteId === selectedAthlete?.forcedecks_id;
    });
  }, [fdDisplayData, selectedAthlete]);
  const {
    data: testDetails,
    isLoading: testDetailsIsLoading,
    isFetching: testDetailsIsFetching,
    error: testDetailsError,
  } = useQuery(
    [
      "forcedecks-team-details",
      JSON.stringify(dateRange),
      selectedAthleteFDData,
    ],
    () =>
      api
        .get(`${apiUrl}/forcedecksTestDetails`, {
          params: {
            teamId: selectedAthleteFDData?.[0].teamId,
            testIds: selectedAthleteFDData?.map((test) => test.id).join(","),
            fromDate: dateRange[0]?.toISOString(),
            toDate: dateRange[1]?.toISOString(),
          },
        })
        .then((data) => {
          return data.data.sort((a: any, b: any) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });
        }),
    {
      enabled:
        !!dateRange[0] &&
        !!dateRange[1] &&
        !!selectedAthleteFDData &&
        (selectedAthleteFDData.length ?? 0) > 0,
    }
  );

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Select
          searchable
          value={selectedAthleteId}
          onChange={(e: any) => {
            setSelectedAthleteId(e);
          }}
          label="Athlete"
          placeholder="Select an athlete"
          data={
            athletes?.map((entry) => {
              return {
                value: entry.id?.toString(),
                label: entry.name,
              };
            }) ?? []
          }
        />
        <div style={{ margin: "1rem 0" }}>
          <DateRangePicker
            label="Date Select"
            placeholder="Pick dates range"
            value={dateRange}
            onChange={setDateRange}
            zIndex={1200}
            withinPortal
            renderDay={(date) => {
              const dateStr = `${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${date.getFullYear()}`;
              const active = athleteData.some((data: any) => {
                return data.totalPlayerLoad > 0 && data.date === dateStr;
              });
              const day = date.getDate();

              return (
                <Indicator size={6} color="red" offset={8} disabled={!active}>
                  <div>{day}</div>
                </Indicator>
              );
            }}
          />
        </div>
        <Tabs value={mainActiveTab} onTabChange={setMainActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="player-load">Player Load</Tabs.Tab>
            <Tabs.Tab value="forcedecks">Force Decks</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="player-load" pt="xs">
            <Select
              value={activeTab}
              onChange={(e: any) => {
                setActiveTab(e);
              }}
              data={["Total Player Load", "Player Load Per Minute"]}
              style={{ paddingBottom: 10 }}
            />
            {activeTab === "Total Player Load" && (
              <DataComponent isLoading={isLoading && isFetching} error={error}>
                <>
                  <Select
                    value={rollingWindowSize}
                    onChange={(e: any) => {
                      setRollingWindowSize(e);
                    }}
                    label="Rolling Player Average"
                    data={["3", "5", "7"]}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "auto ",
                      zIndex: 1000,
                    }}
                  />
                  <Plot
                    style={{ width: "100%" }}
                    useResizeHandler={true}
                    data={[
                      {
                        marker: {
                          color: "#a32135",
                          opacity: 0.95,
                        },
                        type: "scatter",
                        x: selectedAthleteData?.map((data: any) => data.date),
                        y: selectedAthleteData?.map(
                          (data: any) =>
                            Math.round(data.totalPlayerLoadRolling * 100) / 100
                        ),
                        name: "Rolling",
                      },
                      {
                        marker: {
                          color: "#c0c2c5",
                          opacity: 0.95,
                        },
                        type: "bar",
                        x: selectedAthleteData?.map((data: any) => data.date),
                        y: selectedAthleteData?.map(
                          (data: any) =>
                            Math.round(data.totalPlayerLoad * 100) / 100
                        ),
                        name: "Player Load",
                      },
                    ]}
                    layout={{
                      font: {
                        color: "#c0c2c5",
                        size: 10,
                      },
                      autosize: true,
                      yaxis: {
                        range: [0, maxPlayerLoad * 1.1],
                      },
                      plot_bgcolor: "#141517",
                      paper_bgcolor: "#141517",
                      showlegend: false,
                      hovermode: "x unified",
                      margin: {
                        l: 25,
                        r: 0,
                      },
                    }}
                  />
                </>
              </DataComponent>
            )}
            {activeTab === "Player Load Per Minute" && (
              <DataComponent isLoading={isLoading && isFetching} error={error}>
                <>
                  <Select
                    value={rollingWindowSize}
                    onChange={(e: any) => {
                      setRollingWindowSize(e);
                    }}
                    label="Rolling Player Average"
                    data={["3", "5", "7"]}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "auto ",
                      zIndex: 1000,
                    }}
                  />
                  <Plot
                    style={{ width: "100%" }}
                    useResizeHandler={true}
                    data={[
                      {
                        marker: {
                          color: "#a32135",
                          opacity: 0.95,
                        },
                        type: "scatter",
                        x: selectedAthleteData?.map((data: any) => data.date),
                        y: selectedAthleteData?.map(
                          (data: any) =>
                            Math.round(data.playerLoadPerMinuteRolling * 100) /
                            100
                        ),
                        name: "Rolling",
                      },
                      {
                        marker: {
                          color: "#c0c2c5",
                          opacity: 0.95,
                        },
                        type: "bar",
                        x: selectedAthleteData?.map((data: any) => data.date),
                        y: selectedAthleteData?.map(
                          (data: any) =>
                            Math.round(data.playerLoadPerMinute * 100) / 100
                        ),
                        name: "Player Load",
                      },
                    ]}
                    layout={{
                      font: {
                        color: "#c0c2c5",
                        size: 10,
                      },
                      autosize: true,
                      yaxis: {
                        range: [0, maxPlayerLoadPerMinute * 1.1],
                      },
                      plot_bgcolor: "#141517",
                      paper_bgcolor: "#141517",
                      showlegend: false,
                      hovermode: "x unified",
                      margin: {
                        l: 25,
                        r: 0,
                      },
                    }}
                  />
                </>
              </DataComponent>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="forcedecks" pt="xs">
            <Select
              searchable
              value={selectedParameter}
              onChange={(e: any) => {
                setSelectedParameter(e);
              }}
              //label="Force Decks"
              placeholder="Select an parameter"
              data={[
                { value: "ECCENTRIC_PEAK_POWER", label: "Peak Power" },
                { value: "JUMP_HEIGHT", label: "Jump Height" },
                { value: "RSI_MODIFIED", label: "Reactive Strength Index" },
                { value: "CONCENTRIC_RFD", label: "Concentric RFD" },
                { value: "MEAN_TAKEOFF_FORCE", label: "Concentric Mean Force" },
                {
                  value: "MEAN_CONCENTRIC_POWER",
                  label: "Concentric Mean Power",
                },
                {
                  value: "MEAN_ECCENTRIC_FORCE",
                  label: "Eccentric Mean Force",
                },
                {
                  value: "MEAN_ECCENTRIC_POWER",
                  label: "Eccentric Mean Power",
                },
                {
                  value: "FLIGHT_CONTRACTION_TIME_RATIO",
                  label: "Flight Time : Contraction Time",
                },
                { value: "PEAK_LANDING_FORCE", label: "Peak Landing Force" },
              ]}
            />
            <DataComponent
              isLoading={
                (fdIsLoading || testDetailsIsLoading) &&
                (fdIsFetching || testDetailsIsFetching)
              }
              error={fdError ?? testDetailsError}
            >
              <Plot
                style={{ width: "100%" }}
                useResizeHandler={true}
                data={[
                  {
                    marker: {
                      color: "#c0c2c5",
                      opacity: 0.95,
                    },
                    type: "bar",
                    x: testDetails?.map((data: any) => data.date.split("T")[0]),
                    y: testDetails?.map(
                      (data: any) =>
                        Math.round(data[selectedParameter] * 100) / 100
                    ),
                    name: "Test Series",
                  },
                ]}
                layout={{
                  font: {
                    color: "#c0c2c5",
                    size: 10,
                  },
                  autosize: true,
                  yaxis: {
                    title:
                      AXIS_LABEL_MAP[selectedParameter]?.yAxis ??
                      selectedParameter,
                    // range: [0, maxPlayerLoadPerMinute * 1.1],
                  },
                  plot_bgcolor: "#141517",
                  paper_bgcolor: "#141517",
                  showlegend: false,
                  hovermode: "x unified",
                  margin: {
                    l: 45,
                    r: 0,
                  },
                }}
              />
            </DataComponent>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default Individual;
