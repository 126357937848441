import { useQuery } from "@tanstack/react-query";
import { createStyles, Select, Tabs } from "@mantine/core";
import Plot from "react-plotly.js";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "@mantine/dates";
import { DataComponent } from "../components/DataComponent";
import { apiUrl } from "../config";
import api from "../util/api";
import { AXIS_LABEL_MAP, useForceDecksData } from "../individual/Individual";

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.md,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

type IType = {
  dateRange: [Date | null, Date | null];
  setDateRange: (dateRange: [Date | null, Date | null]) => void;
};

const Team: React.FC<IType> = ({ dateRange, setDateRange }) => {
  const [rollingWindowSize, setRollingWindowSize] = useState("3");
  const [activeTab, setActiveTab] = useState<string | null>(
    "average-player-load"
  );
  let { data, error, isLoading, isFetching } = useQuery(
    ["team-data", rollingWindowSize, dateRange],
    () =>
      api
        .get(`${apiUrl}/teamData`, {
          params: {
            startDate: dateRange[0]?.toISOString(),
            endDate: dateRange[1]?.toISOString(),
            rollingWindowSize: rollingWindowSize,
          },
        })
        .then((data) => {
          return data.data.sort(
            (a: any, b: any) =>
              new Date(a.date_id).getTime() - new Date(b.date_id).getTime()
          );
        }),
    { enabled: !!dateRange[0] && !!dateRange[1] }
  );

  const [displayData, setDisplayData] = useState<any[]>();

  const [selectedParameter, setSelectedParameter] = useState(
    "ECCENTRIC_PEAK_POWER"
  );
  const {
    displayData: fdDisplayData,
    isLoading: fdIsLoading,
    isFetching: fdIsFetching,
    error: fdError,
  } = useForceDecksData(dateRange);

  const {
    data: testDetails,
    isLoading: testDetailsIsLoading,
    isFetching: testDetailsIsFetching,
    error: testDetailsError,
  } = useQuery(
    ["forcedecks-team-details", JSON.stringify(dateRange), fdDisplayData],
    () =>
      api
        .get(`${apiUrl}/forcedecksTestDetails`, {
          params: {
            teamId: fdDisplayData?.[0].teamId,
            testIds: fdDisplayData?.map((test) => test.id).join(","),
            fromDate: dateRange[0]?.toISOString(),
            toDate: dateRange[1]?.toISOString(),
          },
        })
        .then((response) =>
          response.data.sort(
            (a: any, b: any) =>
              new Date(a.date).getTime() - new Date(b.date).getTime()
          )
        ),
    {
      enabled:
        !!dateRange[0] &&
        !!dateRange[1] &&
        !!fdDisplayData &&
        (fdDisplayData.length ?? 0) > 0,
    }
  );
  // Remove duplicate dates
  const uniqueDates = useMemo(() => {
    if (!testDetails) {
      return [];
    }

    const dates = testDetails.map((test: any) => test.date);
    return [...new Set(dates)];
  }, [testDetails]);

  // Compute test average for each unique day.
  const testAverages = useMemo(() => {
    if (!testDetails) {
      return [];
    }

    const averages = uniqueDates.map((date) => {
      const tests = testDetails.filter((test: any) => test.date === date);
      const total = tests.reduce((acc: any, test: any) => {
        return acc + test[selectedParameter];
      }, 0);
      return {
        date,
        average: total / tests.length,
      };
    });

    return averages;
  }, [testDetails, selectedParameter, uniqueDates]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setDisplayData(data);
  }, [data]);

  const athleteData = useMemo(() => {
    return (
      displayData?.map((item: any) => {
        return {
          athleteId: item.athlete_id,
          date: item.date_id,
          totalPlayerLoad: item.total_player_load,
          playerLoadPerMinute: item.player_load_per_minute,
          averagePlayerLoadSession: item.average_player_load_session,
          averagePlayerLoadRolling: item.average_player_load_rolling,
          averagePlayerLoadPerMinuteRolling:
            item.player_load_per_minute_rolling,
        };
      }) ?? []
    );
  }, [displayData]);
  const maxPlayerLoad = useMemo(() => {
    return Math.max(...athleteData.map((data: any) => data.totalPlayerLoad));
  }, [athleteData]);

  const { classes } = useStyles();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <>
        <div className={classes.root}>
          <DateRangePicker
            label="Date Select"
            placeholder="Pick dates range"
            value={dateRange}
            onChange={setDateRange}
            zIndex={1200}
            withinPortal
          />

          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List>
              <Tabs.Tab value="average-player-load">
                Average Player Load
              </Tabs.Tab>
              <Tabs.Tab value="player-load-per-minute">
                Player Load Per Minute
              </Tabs.Tab>
              <Tabs.Tab value="forcedecks">Force Decks</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="average-player-load" pt="xs">
              <DataComponent error={error} isLoading={isLoading && isFetching}>
                <>
                  <Select
                    value={rollingWindowSize}
                    onChange={(e: any) => {
                      setRollingWindowSize(e);
                    }}
                    label="Rolling Player Average"
                    data={["3", "5", "7"]}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "auto ",
                      zIndex: 1000,
                    }}
                  />
                  <Plot
                    style={{ width: "100%" }}
                    useResizeHandler={true}
                    data={[
                      {
                        marker: {
                          color: "#a32135",
                          opacity: 0.95,
                        },
                        type: "scatter",
                        x: athleteData?.map((data: any) => data.date),
                        y: athleteData?.map(
                          (data: any) =>
                            Math.round(data.averagePlayerLoadRolling * 100) /
                            100
                        ),
                        name: "Rolling",
                      },
                      {
                        marker: {
                          color: "#c0c2c5",
                          opacity: 0.95,
                        },
                        type: "bar",
                        x: athleteData?.map((data: any) => data.date),
                        y: athleteData?.map(
                          (data: any) =>
                            Math.round(data.averagePlayerLoadSession * 100) /
                            100
                        ),
                        name: "Player Load",
                      },
                    ]}
                    layout={{
                      font: {
                        color: "#c0c2c5",
                        size: 10,
                      },
                      autosize: true,
                      yaxis: {
                        range: [0, maxPlayerLoad * 1.1],
                      },
                      plot_bgcolor: "#141517",
                      paper_bgcolor: "#141517",
                      showlegend: false,
                      hovermode: "x unified",
                      margin: {
                        l: 25,
                        r: 0,
                      },
                    }}
                  />
                </>
              </DataComponent>
            </Tabs.Panel>
            <Tabs.Panel value="player-load-per-minute" pt="xs">
              <DataComponent error={error} isLoading={isFetching}>
                <>
                  <Select
                    value={rollingWindowSize}
                    onChange={(e: any) => {
                      setRollingWindowSize(e);
                    }}
                    label="Rolling Player Average"
                    data={["3", "5", "7"]}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "auto ",
                      zIndex: 1000,
                    }}
                  />
                  <Plot
                    style={{ width: "100%" }}
                    useResizeHandler={true}
                    data={[
                      {
                        marker: {
                          color: "#a32135",
                          opacity: 0.95,
                        },
                        type: "scatter",
                        x: athleteData?.map((data: any) => data.date),
                        y: athleteData?.map(
                          (data: any) =>
                            Math.round(
                              data.averagePlayerLoadPerMinuteRolling * 100
                            ) / 100
                        ),
                        name: "Rolling",
                      },
                      {
                        marker: {
                          color: "#c0c2c5",
                          opacity: 0.95,
                        },
                        type: "bar",
                        x: athleteData?.map((data: any) => data.date),
                        y: athleteData?.map(
                          (data: any) =>
                            Math.round(data.playerLoadPerMinute * 100) / 100
                        ),
                        name: "Player Load",
                      },
                    ]}
                    layout={{
                      font: {
                        color: "#c0c2c5",
                        size: 10,
                      },
                      autosize: true,
                      plot_bgcolor: "#141517",
                      paper_bgcolor: "#141517",
                      showlegend: false,
                      hovermode: "x unified",
                      margin: {
                        l: 25,
                        r: 0,
                      },
                    }}
                  />
                </>
              </DataComponent>
            </Tabs.Panel>
            <Tabs.Panel value="forcedecks" pt="xs">
              <Select
                searchable
                value={selectedParameter}
                onChange={(e: any) => {
                  setSelectedParameter(e);
                }}
                //label="Force Decks"
                placeholder="Select an parameter"
                data={[
                  { value: "ECCENTRIC_PEAK_POWER", label: "Peak Power" },
                  { value: "JUMP_HEIGHT", label: "Jump Height" },
                  { value: "RSI_MODIFIED", label: "Reactive Strength Index" },
                  { value: "CONCENTRIC_RFD", label: "Concentric RFD" },
                  {
                    value: "MEAN_TAKEOFF_FORCE",
                    label: "Concentric Mean Force",
                  },
                  {
                    value: "MEAN_CONCENTRIC_POWER",
                    label: "Concentric Mean Power",
                  },
                  {
                    value: "MEAN_ECCENTRIC_FORCE",
                    label: "Eccentric Mean Force",
                  },
                  {
                    value: "MEAN_ECCENTRIC_POWER",
                    label: "Eccentric Mean Power",
                  },
                  {
                    value: "FLIGHT_CONTRACTION_TIME_RATIO",
                    label: "Flight Time : Contraction Time",
                  },
                  { value: "PEAK_LANDING_FORCE", label: "Peak Landing Force" },
                ]}
              />
              <DataComponent
                isLoading={
                  (fdIsLoading || testDetailsIsLoading) &&
                  (fdIsFetching || testDetailsIsFetching)
                }
                error={fdError ?? testDetailsError}
              >
                <Plot
                  style={{ width: "100%" }}
                  useResizeHandler={true}
                  data={[
                    {
                      marker: {
                        color: "#c0c2c5",
                        opacity: 0.95,
                      },
                      type: "bar",
                      x: testAverages?.map(
                        (data: any) => data.date.split("T")[0]
                      ),
                      y: testAverages?.map((data: any) => {
                        return data.average;
                      }),

                      name: "Test Series",
                    },
                  ]}
                  layout={{
                    font: {
                      color: "#c0c2c5",
                      size: 10,
                    },
                    autosize: true,
                    yaxis: {
                      title:
                        AXIS_LABEL_MAP[selectedParameter]?.yAxis ??
                        selectedParameter,
                      // range: [0, maxPlayerLoadPerMinute * 1.1],
                    },
                    plot_bgcolor: "#141517",
                    paper_bgcolor: "#141517",
                    showlegend: false,
                    hovermode: "x unified",
                    margin: {
                      l: 45,
                      r: 0,
                    },
                  }}
                />
              </DataComponent>
            </Tabs.Panel>
          </Tabs>
        </div>
      </>
    </div>
  );
};

export default Team;
