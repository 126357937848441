import { LoadingOverlay } from "@mantine/core";
import React, { ReactElement } from "react";
import { ServerError } from "./ServerError";

type IDataComponent = {
  isLoading: boolean;
  error: any;
  children: ReactElement;
};

export const DataComponent: React.FC<IDataComponent> = ({
  isLoading,
  error,
  children,
}) => {
  if (error) {
    return <ServerError error={error} />;
  }

  return (
    <div style={{ position: "relative", minHeight: 450 }}>
      <LoadingOverlay visible={isLoading} transitionDuration={500} />
      {children}
    </div>
  );
};
