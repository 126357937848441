import React from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Button,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { apiUrl } from "../../config";
import create from "zustand";

interface AuthStore {
  token?: string;
  refreshToken?: string;
  login: (username: string, password: string) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthStore>()((set, get) => ({
  token: localStorage.getItem("token")!,
  refreshToken: localStorage.getItem("refreshToken")!,
  login: (username: string, password: string) => {
    fetch(`${apiUrl}/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.access) {
          localStorage.setItem("token", data.access);
          localStorage.setItem("refreshToken", data.refresh);

          set({ token: data.access, refreshToken: data.refresh });
        }
      });
  },
  logout: () => {
    set({ token: undefined, refreshToken: undefined });
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  },
}));

const useStyles = createStyles((theme) => ({
  bg: {
    backgroundColor: "#670000",
    backgroundPosition: "left 0px center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage:
      "url(https://images.solecollector.com/complex/images/f_auto,fl_lossy,q_auto,w_1200/tdygnmszx2s5kj7xizum/oklahoma-jordan-brand.jpg)",
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export const AuthenticationImage: React.FC<any> = () => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },
    validate: {},
  });

  const login = useAuthStore((state) => state.login);

  return (
    <div>
      <form
        onSubmit={(e) =>
          form.onSubmit((vals, e) => {
            e.preventDefault();
            login(vals.username, vals.password);
          })(e)
        }
      >
        <div style={{ display: "flex" }}>
          <Paper
            className={classes.form}
            radius={0}
            p={30}
            style={{ height: "100vh" }}
          >
            <Title
              order={2}
              className={classes.title}
              align="center"
              mt="md"
              mb={50}
            >
              OU Basketball Performance
            </Title>

            <TextInput
              label="Email address"
              placeholder="hello@gmail.com"
              size="md"
              {...form.getInputProps("username")}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              mt="md"
              size="md"
              {...form.getInputProps("password")}
            />
            <Button fullWidth mt="xl" size="md" type="submit">
              Login
            </Button>
          </Paper>
          <div className={classes.bg} style={{ flexGrow: 1 }}></div>
        </div>
      </form>
    </div>
  );
};
