const getApiUrl = () => {
//   if (process.env.REACT_APP_UI_API_OVERRIDE) {
//     return process.env.REACT_APP_UI_API_OVERRIDE.trim();
//   }

  return `${window.location.origin}/api`;
};

console.log(`API URL: ${getApiUrl()}`);

export const cachingEnabled = true;
export const apiUrl = getApiUrl();
